/*
 * @Description: 
 * @Author: 
 * @Date: 2023-04-23 10:08:09
 * @LastEditors: koushaoping 1719422019@qq.com
 * @LastEditTime: 2024-03-01 13:56:31
 */
import axios from "axios";
// 导入进度条的库和样式文件
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

export function request(config) {
  let instance = axios.create({
    // baseURL: 'https://test.sysongy.mobi:7000',
    // baseURL: 'https://lng.sysongy.net',
    baseURL: 'https://pms.sysongy.net',
    // baseURL: 'http://192.168.1.82:9001',
    // baseURL: 'http://192.168.1.54:8001/',
    // baseURL: 'https://applet.dev.sysongy.com:7000/',
    timeout: 5000
  });

  // 拦截请求。请求在到达服务器之前会首先调用这个函数对请求做一些预处理
  // 在这里展示进度条
  instance.interceptors.request.use(config => {
    NProgress.start();
    if(localStorage.getItem('token')!==null){
      // 为请求头对象，添加Token验证的Authorization字段
      config.headers.Authorization = localStorage.getItem('token');
    }
    return config;
  });  

  // 拦截响应。在这里隐藏进度条
  instance.interceptors.response.use(response => {
    NProgress.done();
    return response;
  });
  return instance(config);                                                                              
}
